@font-face {
  font-weight: normal;
  font-family: 'iconfont';
  font-style: normal;
  src:
  url('fonts/iconfont.ttf?bdoa84') format('truetype'),
  url('fonts/iconfont.woff?bdoa84') format('woff'),
  url('fonts/iconfont.svg?bdoa84#iconfont') format('svg');
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-weight: normal;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-top-up::before {
  content: "\e946";
}

.icon-systems::before {
  content: "\e905";
}

.icon-join-system::before {
  content: "\e904";
}
.icon-edit::before {
  color: #007aff;
  content: "\e938";
}
.icon-add::before {
  color: #007aff;
  content: "\e939";
}
.icon-build::before {
  color: #0ad9d9;
  content: "\e93a";
}
.icon-delete1::before {
  color: #007aff;
  content: "\e93b";
}
.icon-floor .path1::before {
  color: rgb(66, 189, 244);
  content: "\e93c";
}
.icon-floor .path2::before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e93d";
}
.icon-floor .path3::before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e93e";
}
.icon-floor .path4::before {
  margin-left: -1em;
  color: rgb(66, 189, 244);
  content: "\e93f";
}
.icon-room .path1::before {
  color: rgb(161, 124, 247);
  content: "\e940";
}
.icon-room .path2::before {
  margin-left: -1em;
  color: rgb(161, 124, 247);
  content: "\e941";
}
.icon-room .path3::before {
  margin-left: -1em;
  color: rgb(161, 124, 247);
  content: "\e942";
}
.icon-room .path4::before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e943";
}
.icon-see::before {
  color: #007aff;
  content: "\e944";
}
.icon-warn::before {
  color: #f6890c;
  content: "\e945";
}
.icon-media::before {
  color: #8a95a1;
  content: "\e936";
}
.icon-back::before {
  color: #0071ff;
  content: "\e92c";
}
.icon-alarm-entity::before {
  color: #0071ff;
  content: "\e92d";
}
.icon-monitor::before {
  color: #0071ff;
  content: "\e92e";
}
.icon-round::before {
  color: #01efe9;
  content: "\e92f";
}
.icon-person::before {
  color: #0071ff;
  content: "\e931";
}
.icon-devices::before {
  color: #0071ff;
  content: "\e932";
}
.icon-camera::before {
  color: #0071ff;
  content: "\e933";
}
.icon-park::before {
  color: #0071ff;
  content: "\e934";
}
.icon-delete::before {
  color: #007aff;
  content: "\e929";
}
.icon-addRoot::before {
  color: #007aff;
  content: "\e92a";
}
.icon-addSub::before {
  color: #007aff;
  content: "\e92b";
}
.icon-drivingStore::before {
  content: "\e927";
}
.icon-information::before {
  content: "\e928";
}
.icon-safety::before {
  content: "\e914";
}
.icon-cars::before {
  content: "\e916";
}
.icon-prevention::before {
  content: "\e918";
}
.icon-visitor::before {
  content: "\e919";
}
.icon-alarm::before {
  content: "\e91d";
}
.icon-environment::before {
  content: "\e91e";
}
.icon-meetingRoom::before {
  content: "\e91f";
}
.icon-attendance::before {
  content: "\e920";
}
.icon-entranceGuard::before {
  content: "\e921";
}
.icon-energy::before {
  content: "\e922";
}
.icon-device::before {
  content: "\e923";
}
.icon-dataView::before {
  content: "\e924";
}
.icon-system::before {
  content: "\e925";
}
.icon-light::before {
  content: "\e926";
}
.icon-uniE909::before {
  content: "\e909";
}
.icon-logout::before {
  content: "\e913";
}
.icon-tip-light::before {
  color: #209cff;
  content: "\e90e";
}
.icon-account::before {
  color: #209cff;
  content: "\e910";
}
.icon-close::before {
  color: #999;
  content: "\e90d";
}
.icon-empty::before {
  color: #d8d8d8;
  content: "\e902";
}
.icon-selected::before {
  color: #00d154;
  content: "\e906";
}
.icon-error::before {
  color: #fb0729;
  content: "\e907";
}
.icon-check .path1::before {
  color: rgb(0, 122, 255);
  content: "\e908";
}
.icon-check .path2::before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e90a";
}
.icon-loading::before {
  color: #bac6d2;
  content: "\e90b";
}
.icon-tip::before {
  color: #108ee9;
  content: "\e90c";
}
.icon-enable::before {
  content: "\e95b";
}
.icon-wait::before {
  content: "\e930";
}
.icon-close-two::before {
  content: "\e91c";
}
.icon-upico::before {
  content: "\e91a";
}
.icon-dowico::before {
  content: "\e91b";
}
.icon-check-mark::before {
  content: "\e917";
}
.icon-search::before {
  content: "\e90f";
}
.icon-help::before {
  content: "\e911";
}
.icon-add-to::before {
  content: "\e912";
}
.icon-usercenter::before {
  content: "\e915";
}
.icon-direc-right::before {
  content: "\e900";
}
.icon-direc-left::before {
  content: "\e901";
}
.icon-message::before {
  color: #fff;
  content: "\e935";
}
.icon-trend-right::before {
  color: #007aff;
  content: "\e937";
}
.icon-trend-down::before {
  content: "\e957";
}
.icon-trend-up::before {
  content: "\e958";
}
.icon-themes::before {
  content: "\e903";
}
