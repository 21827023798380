@import './variables.less';
@import './mixins/ellipsis.less';

// ant-message
.ant-message {
  top: initial;
  bottom: 24%;

  .ant-message-notice-content {
    padding: 0;
    border-radius: 4px;
    .ant-message-custom-content {
      padding: 9px 23px;
      border-radius: 4px;
    }
    .ant-message-success {
      background: #e9f9ef;
      border: 1px solid @success-color;
    }
    .ant-message-error {
      background: #ffecef;
      border: 1px solid @error-color;
    }
  }
}

// 公共列表样式
.common-table-list {
  padding: 16px;
  background-color: @white;

  .list-title {
    padding: 6px 24px;
    color: #252525;
    font-size: 14px;
    line-height: @line-height-base;
    background-color: @background-color-base;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }
  .list-obtain {
    margin: 0;
  }
  .list-content {
    .list-item {
      align-items: flex-start;
      margin-top: -1px;
      padding-top: 8px;
      padding-right: 24px;
      padding-bottom: 8px;
      padding-left: 24px;
      font-size: 12px;
      line-height: 20px;
      border-bottom: 1px solid #efefef;
    }
  }
}

// button的样式
.ant-btn {
  + .ant-btn {
    margin-left: 10px;
  }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
}

// 带enterbutton 的搜索框的样式
.ant-input-search-enter-button {
  .ant-input-group-addon {
    .ant-btn {
      width: 32px;
      padding: 0;
    }
  }
}

// select 的选择下拉框的样式
.ant-select-dropdown-menu-item-selected {
  color: @primary-color;
  font-size: @font-size-base;
  background-color: rgba(255, 255, 255, 0.3);
}
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  border-radius: 4px;
}

// 统一form样式
.s-form {
  h3.tit {
    margin-bottom: @margin-sm;
    color: @text-color-dark;
    font-weight: normal;
    font-size: @font-size-base;
  }

  .info-form {
    margin-bottom: @margin-sm;
    overflow: hidden;
    border: 1px solid @border-color-base;
    border-left: 0;
    > .ant-row {
      display: flex;
      align-items: stretch;
      border-bottom: 1px solid @border-color-base;
      &:last-child {
        border-bottom: none;
      }
    }
    .ant-form-item {
      display: flex;
      width: 100%;
      height: 100%;
      .ant-form-item-control,
      .ant-form-item-label {
        min-height: 32px;
        padding: 2px @padding-sm;
        line-height: 32px;
      }
      span.ant-form-item-children {
        display: block;
        .ellipsis();
      }
      .ant-form-item-label {
        min-width: 84px;
        font-size: @font-size-sm;
        text-align: left;
        background-color: @normal-color;
        border-right: 1px solid @border-color-base;
        border-left: 1px solid @border-color-base;
        & > label {
          color: @primary-color;
          &::after {
            display: none;
          }
        }
      }

      .ant-form-item-control-wrapper {
        width: 100%;
        padding: 0;
        overflow: hidden;
      }

      .ant-input {
        &:not(textarea) {
          height: @height-sm;
        }

        margin: 0;
      }

      .textarea-with-count {
        .ant-input {
          height: 100%;
          margin: 0;
          padding: @padding-sm;
          line-height: 1.5;
        }
      }
    }

    &.ant-form-inline .ant-form-item-with-help {
      margin-bottom: 0;
    }

    .has-error .ant-form-explain {
      margin-top: 4px;
    }
    &.bb-0 {
      border-bottom: none;
    }
    .height0 {
      height: 0;
    }
  }
}

// ant-select-tree 公共样式
.ant-select-tree {
  padding: @padding-sm !important;
  .ant-select-tree-switcher,
  .ant-select-tree-title {
    box-sizing: border-box;
  }
}
.ant-select-tree li {
  margin: 0 !important;
}
// .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {

// }
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  color: @primary-color;
}

// modal成功的confirm
// .ant-modal-confirm .ant-modal-body {

// }
.ant-modal-confirm-body {
  .anticon.anticon-info-circle {
    display: none;
  }

  .ant-modal-confirm-title {
    margin-bottom: @margin-md;
    color: @text-color-dark;
    font-size: @font-size-base;
  }
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  display: none;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 8px;
}

// 搜索条件
.s-search-form {
  display: flex;
  .ant-form-item {
    margin-right: @margin-sm;
    margin-bottom: @margin-sm;
    .ant-form-item-label,
    .ant-form-item-control,
    .ant-select,
    .ant-select-selection,
    .ant-select-selection__rendered,
    .ant-form-item-children {
      height: @height-sm;
      line-height: @height-sm;
    }
    .ant-form-item-children {
      display: inline-block;
      height: @height-sm;
      line-height: @height-sm;
    }
    .ant-btn {
      height: @height-sm;
      line-height: @height-sm;
      &.rest-btn {
        margin-left: @margin-sm;
        padding: 0;
        border: none;
        cursor: pointer;
      }
    }
  }
}
// 工具栏
.s-tool-bar {
  display: flex;
  .tool {
    .ant-btn {
      height: @height-sm;
      padding-right: @padding-sm;
      padding-left: @padding-sm;
    }

    margin-right: @margin-sm;
  }
}

// form表单星星的颜色
.ant-form-item-required::before {
  color: #007aff;
}
