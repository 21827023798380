* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button:focus {
  outline: none;
}

body {
  height: 100%;
  margin: 0;
  font-size: 12px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
button,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

input,
textarea {
  margin: 0;
  padding: 0;
  outline: none;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:active,
a:focus,
a:hover {
  text-decoration: none;
}
