@import './mixins/ellipsis.less';
@import './variables.less';

.hidden {
  display: none;
}
.pl-0 {
  padding-left: 0;
}

.clearfix {
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

//float
.f-r {
  float: right;
}
.f-l {
  float: left;
}
.dsn {
  display: none;
}
.ds-lb {
  display: inline-block;
}

//width
.w-300 {
  width: 300px;
}
.w-320 {
  width: 320px;
}

.w-200 {
  width: 200px;
}
.w-400 {
  width: 400px;
}
.w-500 {
  width: 500px;
}

//text-align:
.ta-r {
  text-align: right;
}
.ta-l {
  text-align: left;
}
.ta-c {
  text-align: center;
}

// font-size
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}

// margin
.ml-5 {
  margin-left: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}

// padding
.pr-10 {
  padding-right: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pr-20 {
  padding-right: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pad20 {
  padding: 20px;
}

// vertical-align
.ver-m {
  vertical-align: middle;
}

.ver-tb {
  vertical-align: text-bottom;
}

// color
.primary-color {
  color: @primary-color;
}
.red {
  color: @error-color;
}
.green {
  color: @success-color;
}
.gray {
  color: @normal-color;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  .ellipsis();
}

// 通用的设置
html {
  position: relative;
  overflow-x: auto;
}
body {
  min-width: @screen-xl;
  // overflow: hidden;
  font-family: 'Helvetica', 'Source Han Sans', 'Microsoft YaHei', '微软雅黑', 'PingFang SC', Arials;
}

.transitionChange {
  opacity: 0;
  transition:  0.5s;

  &.bian {
    opacity: 1;
  }
}
