@import '~antd/dist/antd.less';
@import './styles/base.less';
#root {
  height: 100%;
}
.App,.home {
  height: 100%;
}
body > iframe {
  &:last-child {
    pointer-events: none;
  }
}
.nameTooltipClass{
  .ant-tooltip-content .ant-tooltip-inner{
    width: 7rem;
    // height: 1rem;
    // overflow-y: scroll;
  }
}
